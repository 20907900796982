import React from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./firebase"; // Adjust the import path as necessary
import logo from "./static/spaceona.png"; // Adjust the path based on your file structure

const SignInPage = () => {
  const signin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto" src={logo} alt="Spaceona Logo" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Login to your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Or{" "}
          <button
            className="font-medium text-blue-600 hover:text-blue-500"
            onClick={signin}
          >
            create an account
          </button>
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 sm:py-12 sm:shadow sm:rounded-lg sm:px-12">
          <div className="space-y-6">
            <div>
              <button
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                onClick={signin}
              >
                Login with Google
              </button>
            </div>
          </div>
        </div>
        {/* 
                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a Lafayette student?
                    <a href="/email" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                        Join Our Email List
                    </a>
                </p> */}
      </div>
    </div>
  );
};

export default SignInPage;
